.selected-color {
    display: inline-block;
    padding: 0.5rem;
    border-radius: 4px;
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    font-weight: bold;
    /* margin-left: 0.5rem; */
    
  }

  .color-picker-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .color-picker-button {
    margin-right: 0.5rem;
  }